import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'incidents',
        loadChildren: () => import('../../incidents/incidents.module').then(m => m.IncidentsModule)

    },
    {
        path: 'workflows',
        loadChildren: () => import('../../workflows/workflows.module').then(m => m.WorkflowsModule)

    },
    {
        path: 'incident-types',
        loadChildren: () => import('../../incident-types/incident-types.module').then(m => m.IncidentTypesModule)

    },
    {
        path: 'priorities',
        loadChildren: () => import('../../priorities/priorities.module').then(m => m.PrioritiesModule)

    },
    {
        path: 'objects',
        loadChildren: () => import('../../objects/objects.module').then(m => m.ObjectsModule)

    },
    {
        path: 'hooks',
        loadChildren: () => import('../../webhooks/webhooks.module').then(m => m.WebhooksModule)

    },
    {
        path: 'users',
        loadChildren: () => import('../../users/users.module').then(m => m.UsersModule)

    },
    {
        path: 'actions',
        loadChildren: () => import('../../actions/actions.module').then(m => m.ActionsModule)

    },
    {
        path: 'drivers',
        loadChildren: () => import('../../drivers/drivers.module').then(m => m.DriversModule)

    },
    {
        path: 'flags',
        loadChildren: () => import('../../flags/flags.module').then(m => m.FlagsModule)

    },
    {
        path: 'emailapp',
        loadChildren: () => import('../../emailapp/emailapp.module').then(m => m.EmailappModule)

    },
    {
        path: 'chat-box',
        loadChildren: () => import('../../chat-box/chat-box.module').then(m => m.ChatBoxModule)

    },
    {
        path: 'file-manager',
        loadChildren: () => import('../../file-manager/file-manager.module').then(m => m.FileManagerModule)

    },
    {
        path: 'contatcs',
        loadChildren: () => import('../../contacts/contacts.module').then(m => m.ContactsModule)

    },
    {
        path: 'invoice',
        loadChildren: () => import('../../invoice/invoice.module').then(m => m.InvoiceModule)

    },
    {
        path: 'calendar',
        loadChildren: () => import('../../fullcalendar/fullcalendar.module').then(m => m.FullcalendarModule)

    },
    {
        path: 'widgets',
        loadChildren: () => import('../../widgets/widgets.module').then(m => m.WidgetsModule)

    },
    {
        path: 'user-profile',
        loadChildren: () => import('../../user-profile/user-profile.module').then(m => m.UserProfileModule)

    },
    {
        path: 'components',
        loadChildren: () => import('../../components/components.module').then(m => m.ComponentsModule)
    },
    {
        path: 'ng-components',
        loadChildren: () => import('../../ng-components/ng-components.module').then(m => m.NgComponentsModule)
    },
    {
        path: 'content',
        loadChildren: () => import('../../content/content.module').then(m => m.ContentModule)
    },
    {
        path: 'icons',
        loadChildren: () => import('../../icons/icons.module').then(m => m.IconsModule)
    },
    {
        path: 'form',
        loadChildren: () => import('../../form/form.module').then(m => m.FormModule)
    },
    {
        path: 'faq',
        loadChildren: () => import('../../faq/faq.module').then(m => m.FaqModule)
    },
    {
        path: 'pricing',
        loadChildren: () => import('../../pricing/pricing.module').then(m => m.PricingModule)
    },
    {
        path: 'timeline',
        loadChildren: () => import('../../timeline/timeline.module').then(m => m.TimelineModule)
    },
    {
        path: 'charts',
        loadChildren: () => import('../../charts/chart.module').then(m => m.ChartModule)
    },
    {
        path: 'maps',
        loadChildren: () => import('../../maps/maps.module').then(m => m.MapsModule)

    }
];
