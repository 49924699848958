import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterColumns'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, needle: any): boolean {
    if(value.length == 0) return false;
    // Parse all array elements to integer
    // var result = value.map(function (x) { 
    //   return parseInt(x, 10); 
    // });
    if(value.indexOf(needle) >= 0){
      return true;
    }
   
    return false;
  }

}
