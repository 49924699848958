import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class IncidentsService {

  constructor(private httpService: HttpService) {}

  createIncidentUpdate(postData: any): Observable<any> {
    return this.httpService.post('incident_updates/create', postData);
  }

  updateIncident(incident_id: number, postData: any): Observable<any> {
    return this.httpService.put('incidents/update/'+incident_id, postData);
  }

  getIncidentUpdates(incident_id): Observable<any> {
    return this.httpService.get('incident_updates/list?incident_id='+incident_id);
  }

  getIncidents(params): Observable<any> {

    console.log('incidents service function..', params);

    params['page'] = params.start / params.length + 1,
    params['perPage'] = params.length;
    
    if(params.it_id == undefined){
      params['it_id'] = 0;
    }

    let orderColumnIndex = params.order[0].column;
    let orderColumn = params.columns[orderColumnIndex].data;
    let orderSort = params.order[0].dir;

    console.log('orderSort - ', orderSort);
    

    let uri = `incidents/list/${params.it_id}?page=${params.page}&perPage=${params.perPage}`;
    if(params.period) uri += `&period=${params.period}`;
    if(params.date_from) uri += `&date_from=${params.date_from}`;
    if(params.date_to) uri += `&date_to=${params.date_to}`;
    if(params.object_id) uri += `&object_id=${params.object_id}`;
    if(params.driver_id) uri += `&driver_id=${params.driver_id}`;
    if(params.ip_id) uri += `&ip_id=${params.ip_id}`;
    if(params.closed_by_cu_id) uri += `&closed_by_cu_id=${params.closed_by_cu_id}`;
    if(params.ws_id) uri += `&ws_id=${params.ws_id}`;
    if(params.incident_status) uri += `&incident_status=${params.incident_status}`;
    if(params.geofence) uri += `&geofence=${params.geofence}`;
    if(params.model) uri += `&model=${params.model}`;
    if(params.action_id) uri += `&action_id=${params.action_id}`;
    if(params.flag_ids) uri += `&flag_ids=${params.flag_ids}`;
    if(params.closed_no_action) uri += `&closed_no_action=${params.closed_no_action}`;
    if(params.closed_with_action) uri += `&closed_with_action=${params.closed_with_action}`;
    
    uri += `&sortColumn=${orderColumn}&sortDirection=${orderSort}`;
    

    return this.httpService.get(uri);

  }

  getIncident(incident_id: number): Observable<any> {
    return this.httpService.get('incidents/view/'+incident_id);
  }

  createPriority(postData: any): Observable<any> {
    return this.httpService.post('incident_priorities/create', postData);
  }

  updatePriority(postData: any, ip_id): Observable<any> {
    return this.httpService.put('incident_priorities/update/'+ip_id, postData);
  }

  getPriorities(): Observable<any> {
    return this.httpService.get('incident_priorities/list');
  }

  getPriority(ip_id): Observable<any> {
    return this.httpService.get('incident_priorities/view/'+ip_id);
  }

  getIncidentTypes(): Observable<any> {
    return this.httpService.get('incident_types/list');
  }

  getIncidentType(it_id): Observable<any> {
    return this.httpService.get('incident_types/view/'+it_id);
  }

  createIncidentType(postData: any): Observable<any> {
    return this.httpService.post('incident_types/create', postData);
  }

  updateIncidentType(postData: any, it_id): Observable<any> {
    return this.httpService.put('incident_types/update/'+it_id, postData);
  }

  getUsers(): Observable<any> {
    return this.httpService.get('users/list');
  }

}
