import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { ColorSwitcherComponent } from './color-switcher/color-switcher.component';
import { ExcerptPipe } from './pipes/excerpt.pipe';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { ISOStringPipe } from './pipes/isostring.pipe';
import { FilterPipe } from './pipes/filter.pipe';



@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ColorSwitcherComponent,
        NgbModule,
        ExcerptPipe,
        Nl2brPipe,
        ISOStringPipe,
        FilterPipe
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ColorSwitcherComponent,
        ExcerptPipe,
        Nl2brPipe,
        ISOStringPipe,
        FilterPipe
    ],
    providers: [ ],
})
export class SharedModule { }
