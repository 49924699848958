// Dispatches requests directly to the backend, without going through the interceptor chain.

import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenRefreshService {

  private http: HttpClient;
  private options: any;

  constructor(
      private handler: HttpBackend,
  ) {
    this.http = new HttpClient(handler);
  }


  refreshToken(token: string): Observable<any> {

    let reqheaders={};
    let reQheaders = new HttpHeaders().set('Authorization', 'Bearer '+token).append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    this.options = { headers: reQheaders, withCredintials: true };

    const url = `${environment.baseURL}/authentication/refresh`;
    return  this.http.post(url, [], this.options);

  }
}
