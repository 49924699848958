<!--header-->
<header class="top-header">
	<nav class="navbar navbar-expand">
		<div class="left-topbar d-flex align-items-center">	<a href="#" (click)="$event.preventDefault()" (click)="toggleSidebar()" class="toggle-btn"><i class="bx bx-menu"></i>
			</a>
		</div> 
		<!-- <div class="flex-grow-1 search-bar">
			<div class="input-group">
				<div class="input-group-prepend search-arrow-back">
					<button class="btn btn-search-back" type="button"><i class="bx bx-arrow-back"></i>
					</button>
				</div>
				<input type="text" class="form-control" placeholder="search" />
				<div class="input-group-append">
					<button class="btn btn-search" type="button"><i class="lni lni-search-alt"></i>
					</button>
				</div>
			</div>
		</div> -->
		<div class="right-topbar ml-auto">
			<ul class="navbar-nav">

				<!-- <li ngbDropdown class="nav-item dropdown-property-switch">
					<a class="nav-link position-relative" href="#" (click)="$event.preventDefault()" id="propertiesDropdown" style="font-size:16px;" ngbDropdownToggle>	<i class="bx bx-home vertical-align-middle"></i>
             {{ propertyData.property_name }}
					</a>

					<div ngbDropdownMenu aria-labelledby="propertiesDropdown" class="dropdown-menu-right">
						<a *ngFor="let property of propertiesData; index as i" ngbDropdownItem href="#" (click)="$event.preventDefault(); changeProperty(property.property_id)"><span>{{ property.property_name }}</span></a>
					</div>

				</li> -->
				<li ngbDropdown class="nav-item dropdown-user-profile">
					  <!-- <a class="nav-link dropdown-toggle-nocaret" href="#" (click)="$event.preventDefault()" id="userDropdown" ngbDropdownToggle> -->
						<div class="media user-box align-items-center" (click)="$event.preventDefault()" id="userDropdown" ngbDropdownToggle>
							<div class="media-body user-info">
								<p class="user-name mb-0">{{ authUser.user_first_name }} {{ authUser.user_last_name }}</p>
								<p *ngIf="authUser.user_first_name" class="designattion mb-0">
									Available
								</p>
							</div>
						  <img src="assets/images/avatars/avatar.png" class="user-img">
						</div>
				  <!-- </a> -->
					<div ngbDropdownMenu aria-labelledby="userDropdown" class="dropdown-menu-right">
						<!-- <a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="bx bx-user"></i><span>Profile</span></a>
						<a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="bx bx-cog"></i><span>Settings</span></a>
						<a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="bx bx-tachometer"></i><span>Dashboard</span></a>
						<a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="bx bx-wallet"></i><span>Earnings</span></a>
						<a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="bx bx-cloud-download"></i><span>Downloads</span></a> -->
						<!-- <div class="dropdown-divider mb-0"></div> -->
						<a ngbDropdownItem href="#" (click)="logOut()"><i class="bx bx-power-off"></i><span>Logout</span></a>
						<a ngbDropdownItem href="javascript:void(0)" (click)="openModal(passwordModal)"><i class="bx bx-lock"></i><span>Change Password</span></a>
						<a ngbDropdownItem href="javascript:void(0)" (click)="openModal(settingsModal, 'settingsModal')"><i class="bx bx-cog"></i><span>Settings</span></a>
					</div>
				</li>
				<!-- <li ngbDropdown class="nav-item dropdown-language">
					<a class="nav-link dropdown-toggle-nocaret" href="#" (click)="$event.preventDefault()" id="dropdownBasic4" ngbDropdownToggle>
						<div class="lang d-flex">
							<div><i class="flag-icon flag-icon-um"></i>
							</div>
							<div><span>En</span>
							</div>
						</div>
					</a>
					<div ngbDropdownMenu aria-labelledby="dropdownBasic4" class="dropdown-menu-right">
						<a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="flag-icon flag-icon-de"></i><span>German</span></a>
						<a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="flag-icon flag-icon-fr"></i><span>French</span></a>
						<a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="flag-icon flag-icon-um"></i><span>English</span></a>
						<a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="flag-icon flag-icon-in"></i><span>Hindi</span></a>
						<a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="flag-icon flag-icon-cn"></i><span>Chinese</span></a>
						<a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="flag-icon flag-icon-ae"></i><span>Arabic</span></a>
					</div>
				</li> -->
			</ul>
		</div>
	</nav>
</header>
<!--end header-->

<ng-template #passwordModal let-modal>
	<div class="modal-header">
	  <h4 class="modal-title" id="modal-basic-title">Update Password</h4>
	  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
	</div>
	<form [formGroup]="passwordForm" (ngSubmit)="onChangePassword()">
	<div class="modal-body p-4">
	  <div class="form-body">
		<div class="form-group col-md-12">
			<label>New Password</label>
			<input type="password" formControlName="new_password" class="form-control" autocomplete="off"/>
		</div>
		<div class="form-group col-md-12">
			<label>Repeat Password</label>
			<input type="password" formControlName="pass_confirm" class="form-control" autocomplete="off"/>
		</div>
	  </div>
	</div>
  
	<div class="modal-footer">
	  <button type="submit" class="btn btn-info" [disabled]="pwProcessing || passwordForm.invalid">
		Submit<span *ngIf="pwProcessing">ting..</span>
	  </button>
	</div>
	</form>
  </ng-template>

  <ng-template #settingsModal let-modal>
	<div class="modal-header">
	  <h4 class="modal-title" id="modal-basic-title">Settings</h4>
	  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
	</div>
	<form [formGroup]="settingsForm" (ngSubmit)="onSubmitSettings()">
	<div class="modal-body p-4">
	  <div class="form-body" *ngIf="!loadingSettings">
		<h5>Show Incident Columns</h5>
        <div *ngFor="let column of incidentColumns; index as i" class="form-group row" style="margin-bottom:0; padding:0">
			<label class="col-sm-3 col-form-label">{{column.name}}</label>
			<div class="col-sm-9">
			<label class="col-form-label"><input type="checkbox" formControlName="{{column.field}}" value="1"></label>
			</div>
		</div>
	  </div>
	  <p *ngIf="loadingSettings">Loading..</p>
	</div>

	
  
	<div class="modal-footer">
	  <button type="submit" class="btn btn-info" [disabled]="stProcessing">
		Sav<span *ngIf="!stProcessing">e</span><span *ngIf="stProcessing">ing..</span>
	  </button>
	</div>
	</form>
  </ng-template>
