import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  headers = new HttpHeaders();
  options = { headers: this.headers, withCredintials: true };
  apiUrl = `${environment.baseURL}/`; 

  constructor(private http: HttpClient) {

  }

  post(serviceName: string, data: any, token?: string) {

    if(token){
      let reqheaders={};
      let reQheaders = new HttpHeaders().set('Authorization', 'Bearer '+token); //.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

      this.options = { headers: reQheaders, withCredintials: true };

    }

    // Create form data
    // let body = new FormData();
    // for (let key in data) {
    //     body.append(key, data[key]);
    // }

    const formBody = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');

    const url = this.apiUrl + serviceName;
    return this.http.post(url, formBody, this.options);
  }

  get(serviceName: string) {

    // if(token){
    //   let reqheaders={};
    //   let reQheaders = new HttpHeaders().set('Authorization', 'Bearer '+token);
    //   this.options = { headers: reQheaders, withCredintials: true };
    // }

    const url = this.apiUrl + serviceName;
    return this.http.get(url, this.options);
  }

  put(serviceName: string, data: any) {

    // Create form data - Does not work for PUT
    // let body = new FormData();
    // for (let key in data) {
    //     body.append(key, data[key]);
    // }

    // Create x-www-form-urlencoded payload
    const formBody = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');

    const url = this.apiUrl + serviceName;
    return this.http.put(url, formBody, this.options);

  }
}
