import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpService: HttpService) {}

  getUsers(): Observable<any> {
    return this.httpService.get('users/list');
  }

  getUser(user_id: number): Observable<any> {
    return this.httpService.get('users/view/'+user_id);
  }

  createUser(postData: any): Observable<any> {
    return this.httpService.post('users/create', postData);
  }

  updateUser(user_id: number, postData: any): Observable<any> {
    return this.httpService.put('users/update/'+user_id, postData);
  }

  updatePassword(postData: any): Observable<any> {
    return this.httpService.post('authentication/update_password', postData);
  }

  updateCustomerSettings(postData: any): Observable<any> {
    return this.httpService.post('customers/update_settings', postData);
  }

  getCustomerSettings(): Observable<any> {
    return this.httpService.get('customers/get_settings');
  }
}
