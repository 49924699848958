import { Pipe, PipeTransform } from '@angular/core';
/*
 * Truncates a string
 * Takes a string argument and trims it to required length.
 * Usage:
 *   value | excerpt:24
*/

@Pipe({
  name: 'excerpt'
})
export class ExcerptPipe implements PipeTransform {

  transform(value: string, len = 24, link = true): string {

    if(value != undefined && value.length > len){
       const excerpt = value.substring(0, len);
       return (link)? `<a title='${value}' href=''>${excerpt}..</a>` : excerpt+'..';
    }

    return value;
  }

}
