import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';
import { AuthConstants } from './../config/auth-constants';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData$ = new BehaviorSubject<any>([]);
  propertyData$ = new BehaviorSubject<any>([]);
  settingsData$ = new BehaviorSubject<any>([]);


  constructor(private httpService: HttpService,
              private storageService: StorageService,
              private router: Router) { }

  // ifLoggedIn() {
  //   this.storageService.get(AuthConstants.AUTH).then((response) => {
  //     if (response) {
  //       this.authState.next(true);
  //     }
  //   });
  // }

  login(postData: any): Observable<any> {
    return this.httpService.post('authentication/login', postData);
  }

  logout() {
    this.storageService.removeStorageItem(AuthConstants.AUTH).then(res => {
      this.userData$.next('');
      this.router.navigate(['/auth/login']);
    });
  }

  forgotPassword(postData: any): Observable<any> {
    return this.httpService.post('authentication/reset', postData);
  }

  newPassword(postData: any): Observable<any> {
    return this.httpService.post('authentication/new_password', postData);
  }

  getUserData() {
    this.storageService.get(AuthConstants.AUTH).then(res => {
      this.userData$.next(res);
    });
  }

  getPropertyData() {
    this.storageService.get(AuthConstants.PROPERTY).then(res => {
      this.propertyData$.next(res);
    });
  }

  getSettingsData() {
    this.storageService.get(AuthConstants.SETTINGS).then(res => {
      console.log('auth getSettingsData', res);
      this.settingsData$.next(res);
    });
  }

  inviteRegister(postData: any): Observable<any> {
    return this.httpService.post('authentication/invite_register', postData);
  }

  inviteLogin(postData: any): Observable<any> {
    return this.httpService.post('authentication/invite_login', postData);
  }

  refreshToken(token: any): Observable<any> {
    return this.httpService.post('authentication/refresh', [], token);
  }


}
