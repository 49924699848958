import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-color-switcher',
  templateUrl: './color-switcher.component.html',
  styleUrls: ['./color-switcher.component.scss']
})
export class ColorSwitcherComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    /*switcher*/
    $(".switcher-btn").on("click", function () {
      $(".switcher-wrapper").toggleClass("switcher-toggled");
    });
    $("#darkmode").on("click", function () {
      $("html").addClass("dark-theme");
    });
    $("#lightmode").on("click", function () {
      $("html").removeClass("dark-theme");
    });
    $("#DarkSidebar").on("click", function () {
      $("html").toggleClass("dark-sidebar");
    });
    $("#ColorLessIcons").on("click", function () {
      $("html").toggleClass("ColorLessIcons");
    });
    
  }

}
