import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() { }

  // Store the value
  async store(storageKey: string, value: any) {
    const encryptedValue = btoa(escape(JSON.stringify(value)));
    // console.log('unencryptedValue', value);
    // console.log('storageKey', storageKey);
    // await Storage.set({
    //   key: storageKey,
    //   value: encryptedValue
    // });

    await localStorage.setItem(storageKey, encryptedValue);

  }

  // Get the value
  async get(storageKey: string) {
    const ret = await localStorage.getItem(storageKey);
    if(ret){
      return JSON.parse(unescape(atob(ret)));
    }

    return false;
  }
  //
  // Get the value
  async getToken(storageKey: string) {
    const ret = await localStorage.getItem(storageKey);
    let userData = JSON.parse(unescape(atob(ret)));
    if(userData.access_token){
      return userData.access_token;
    }
    return false;
  }

  async removeStorageItem(storageKey: string) {
    await localStorage.removeItem(storageKey);
  }

  // Clear storage
  async clear() {
    await localStorage.clear();
  }
}
