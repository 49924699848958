import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { SidebarService } from "../sidebar/sidebar.service";
import { WorkflowsService } from './../../services/workflows.service';
import { IncidentsService } from './../../services/incidents.service';
import { AuthService } from './../../services/auth.service';

import * as $ from 'jquery';


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {

    public menuItems: any[];
    public userRole: any;


    constructor( public sidebarservice: SidebarService,
                 private auth: AuthService,
                 private router: Router,
                 // private workflows: WorkflowsService,
                 private incidentsService: IncidentsService) {

                    this.toggleSidebar();

        router.events.subscribe( (event: Event) => {

            if (event instanceof NavigationStart) {
                // Show loading indicator
            }

            if (event instanceof NavigationEnd && $(window).width() < 1025 && ( document.readyState == 'complete' || false ) ) {

                this.toggleSidebar();
                // Hide loading indicator

            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });

        this.auth.userData$.subscribe((res: any) => {
            this.userRole = res.cu_role;
          });

    }
    
    // lev: Toggle full sidebar on mouse hover
    onSidebarMouseEnter(){
        console.log('onSidebarMouseEnter..');
        if ($(".wrapper").hasClass("nav-collapsed")) {
            // unpin sidebar when hovered
            $(".wrapper").addClass("sidebar-hovered");
        } else {
            $(".wrapper").removeClass("sidebar-hovered");
        }
    }

    onSidebarMouseLeave(){
        $(".wrapper").removeClass("sidebar-hovered");
    }

    toggleSidebar() {

        console.log('getSidebarState', this.sidebarservice.getSidebarState())
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

        if ($(".wrapper").hasClass("nav-collapsed")) {
            // unpin sidebar when hovered
            $(".wrapper").removeClass("nav-collapsed");
            $(".sidebar-wrapper").unbind( "hover");
        } else {
            $(".wrapper").addClass("nav-collapsed");
            // $(".sidebar-wrapper").hover(
            //     function () {
            //         console.log('sidebar-hovered..');
            //         $(".wrapper").addClass("sidebar-hovered");
            //     },
            //     function () {
            //         $(".wrapper").removeClass("sidebar-hovered");
            //     }
            // )

        }

    }

    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }


    ngOnInit() {


        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.incidentSubmenu();
        this.filterMenuByRole();
        $.getScript('./assets/js/app-sidebar.js');

    }

    incidentSubmenu() {
      
        this.incidentsService.getIncidentTypes().subscribe(
        (res: any) => {
          // Build Incident submenu items
          var index = this.menuItems.findIndex(x => x.title ==="Incidents"); // find index of 'Incidents'
          for (let k in res.data) {
             let menuTitle = (res.data[k].it_display_name)? res.data[k].it_display_name : res.data[k].it_name ;
             this.menuItems[index]['submenu'][k] = {path: '/incidents/list/'+res.data[k].it_id, title: menuTitle, icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass:'', isExternalLink: false, submenu: []}
          }

          // Link for all incidents 
          let firstItem = {path: '/incidents/list/0', title: 'All Incidents', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass:'', isExternalLink: false, submenu: []}
          this.menuItems[index]['submenu'].unshift(firstItem);

        }
      );

    }

    filterMenuByRole(){

        if(this.userRole == 'User'){

            var newMenuItems = this.menuItems.filter(function(item){ 
                console.log('item', item['title']);
                return (item['title'] == 'Incidents' || item['title'] == 'Dashboard'); 
                
            });
            console.log('newMenuItems', newMenuItems);
            this.menuItems = newMenuItems;
        }
        
    }

}
