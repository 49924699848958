import { Component , OnInit } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';
import { AuthService } from '../../services/auth.service';
import { AuthConstants } from '../../config/auth-constants';
import { StorageService } from '../../services/storage.service';
import { UsersService } from '../../services/users.service';
import { ToastService } from '../../services/toast.service';
import { HelperService } from '../../services/helper.service';
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit{

    public authUser: any;
    public passwordForm: FormGroup;
    public settingsForm: FormGroup;
    public pwProcessing: any;
    public stProcessing: any;
    
    loadingSettings: boolean = false;     
    incidentColumns: any[] = [
        // Define your table columns here
        { field: 'incident_id', name: 'Incident #', show: true },
        { field: 'incident_date_created', name: 'Date Created', show: true },
        { field: 'incident_date_closed', name: 'Date Closed', show: true },
        { field: 'incident_date', name: 'Incident Date', show: true },
        { field: 'duration', name: 'Open For', show: true },
        { field: 'it_name', name: 'Type', show: true },
        { field: 'object_name', name: 'Object', show: true },
        { field: 'incident_info', name: 'Incident Info', show: true },
        { field: 'driver_name', name: 'Driver', show: false },
        { field: 'incident_geofence', name: 'Geofence', show: false },
        { field: 'incident_model', name: 'Model', show: false },
        { field: 'ws_name', name: 'Status', show: true },
        { field: 'ip_name', name: 'Priority', show: true },
        { field: 'closed_by_user', name: 'Closed By', show: false },
        { field: 'action_name', name: 'Action', show: false },
        { field: 'flag_names', name: 'Flags', show: false },
      ];

    constructor(public sidebarservice: SidebarService,
                private auth: AuthService,
                private storageService: StorageService,
                private modalService: NgbModal,
                private router: Router,
                private _fb: FormBuilder,
                private usersService: UsersService,
                private helper: HelperService,
                private toast: ToastService) {

        this.passwordForm = this._fb.group({
          new_password: ['', Validators.required],
          pass_confirm: ['', Validators.required],
        });  
        
        this.settingsForm = this._fb.group({
            incident_id: [''],
            incident_date_created: [''],
            incident_date_closed: [''],
            incident_date: [''],
            duration: [''],
            it_name: [''],
            object_name: [''],
            incident_info: [''],
            driver_name: [''],
            incident_geofence: [''],
            incident_model: [''],
            ws_name: [''],
            ip_name: [''],
            closed_by_user: [''],
            action_name: [''],
            flag_names: [''],
          });
       


    }

    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
    }

    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }

    logOut(){
      this.auth.logout();
    }

    ngOnInit() {

        this.auth.userData$.subscribe((res: any) => {
          this.authUser = res;
        });

        /* Search Bar */
        $(function () {
            $(".search-btn-mobile").on("click", function () {
                $(".search-bar").addClass("full-search-bar");
            });
            $(".search-arrow-back").on("click", function () {
                $(".search-bar").removeClass("full-search-bar");
            });
        });

    }

    openModal(modal, name='') {
        if(name == 'settingsModal'){
            this.setCustomerSettings();
        }
        this.modalService.open(modal).result.then((result) => {
        // this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    onChangePassword(){

        this.pwProcessing = true;

        let postData = {
            'new_password': this.passwordForm.controls.new_password.value,
            'pass_confirm': this.passwordForm.controls.pass_confirm.value
        };

        this.usersService.updatePassword(postData).subscribe(

        (res: any) => {
            let msgs = this.helper.cleanMsgArray(res.messages);
            if (res.error) {
                this.toast.show(msgs[0], { classname: 'bg-danger text-light', autohide: true});
            } else {
                this.toast.show(msgs[0], { classname: 'bg-success text-light', autohide: true});
                this.passwordForm.reset();
                this.modalService.dismissAll();
            }
            this.pwProcessing = false;
        },
        (error: any) => {
            let msgs = this.helper.cleanMsgArray(error.error.messages);
            this.toast.show(msgs[0], { classname: 'bg-danger text-light', autohide: true});
            this.pwProcessing = false;
        }

        );


    }

    onSubmitSettings(){

        this.stProcessing = true;
        let postData = {};
       
        for(let key in this.incidentColumns){
            this.incidentColumns[key]['show'] = this.settingsForm.controls[this.incidentColumns[key].field].value;
            postData[this.incidentColumns[key].field] = this.settingsForm.controls[this.incidentColumns[key].field].value;
        }
        
       // console.log('this.incidentColumns', Object.values(this.incidentColumns));

        this.usersService.updateCustomerSettings(postData).subscribe(

            (res: any) => {
                let msgs = this.helper.cleanMsgArray(res.messages);
                if (res.error) {
                    this.toast.show(msgs[0], { classname: 'bg-danger text-light', autohide: true});
                } else {
                    this.storageService.store(AuthConstants.SETTINGS, postData);
                    this.toast.show(msgs[0], { classname: 'bg-success text-light', autohide: true});
                    this.modalService.dismissAll();
                    window.location.reload();
                }
                this.stProcessing = false;
            },
            (error: any) => {
                let msgs = this.helper.cleanMsgArray(error.error.messages);
                this.toast.show(msgs[0], { classname: 'bg-danger text-light', autohide: true});
                this.stProcessing = false;
            }
    
        );
        
    }

    setCustomerSettings() {
        this.loadingSettings = true;
        this.usersService.getCustomerSettings().subscribe(
        (res: any) => {
          this.loadingSettings = false;
          if(res.data != undefined){
            this.incidentColumns = res.data.incident_columns;
            this.updateSettingFormValues();
          }
          
        },
        (error: any) => {
          this.loadingSettings = false;
          let msgs = this.helper.cleanMsgArray(error.error.messages);
          // this.toast.show(msgs[0], { classname: 'bg-danger text-light', autohide: true});
        }
      );
  
    }
    
    // Update settings form field values
    updateSettingFormValues(){

        for(let key in this.incidentColumns){
            if (this.settingsForm.controls[this.incidentColumns[key].field]) {
            this.settingsForm.controls[this.incidentColumns[key].field].setValue(this.incidentColumns[key].show);
            console.log(this.incidentColumns[key].field, this.incidentColumns[key].show);
            }
        }
 
    }


}
