import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toISOString'
})
export class ISOStringPipe implements PipeTransform {

  transform(value: string): string {

   if(value){
     const d = new Date(value);
     return d.toISOString();
   }

   return null;

  }

}
