import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from './services/auth.service';
import { ToastService } from './services/toast.service';
import { TokenRefreshService } from './services/token-refresh.service';

import { StorageService } from './services/storage.service';
import { AuthConstants } from './config/auth-constants';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    public token: any;
    public userData: any;
    constructor(private auth: AuthService,
                private toast: ToastService,
                private storageService: StorageService,
                private refresh: TokenRefreshService,
                private router: Router) {

      this.auth.userData$.subscribe((res: any) => {


        if(res.access_token != undefined){
           this.token = res.access_token;
        }
        
        this.userData = res;
        this.checkToken();

      });
    }


    refreshToken(){

      this.refresh.refreshToken(this.token).subscribe(

         (res: any) => {
             this.storageService.store(AuthConstants.AUTH, res.data);
         },
         (error: any) => {
            console.log('refresh error', error);
         }
       );

    }

    checkToken(){
      // console.log('resx', this.res);
      if(this.userData.access_token != undefined){

        
        // Get current time and token expiry time
        let timestamp_now = Math.floor(Date.now() / 1000);
        let expiry_time = Math.floor((this.userData.access_token_expiry - timestamp_now) / 60); // in minutes
        console.log('expiry_time', expiry_time);

        // If token expiry time is less than 20 minutes refresh token
        if(expiry_time > 0 && expiry_time <= 20){
           this.refreshToken();
        }

        if(expiry_time <= 0){

          alert('Your session has expired please login again');
          let redirect_url = 'auth/login?_rdr='+ btoa(this.router.url);
          //   this.router.navigate([`${redirect_url}`]);
          window.location.href = redirect_url;

        }

     }

    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

       // this.checkToken();

        if (this.token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + this.token) });
        }

        if(request.method != 'GET'){ // Set x-www-form-urlencoded header
          request = request.clone({ headers: request.headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') });
        }

       // request = request.clone({ headers: request.headers.set('Access-Control-Allow-Origin', 'http://localhost:8180') });

        // if (!request.headers.has('Content-Type')) {
        //     request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        // }
        //
        // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log('HttpInterceptor error', error);

                //  alert(error.error.messages[0]);
                //this.toast.show(error.error.messages[0], { classname: 'bg-danger text-light', autohide: true});
                return throwError(error);
            }));
    }
}
