<!--start switcher-->
<div class="switcher-wrapper">
	<!-- <div class="switcher-btn"> <i class='bx bx-cog bx-spin'></i>
	</div> -->
	<div class="switcher-body">
		<h5 class="mb-0 text-uppercase">Theme Customizer</h5>
		<hr/>
		<h6 class="mb-0">Theme Styles</h6>
		<hr/>
		<div class="d-flex align-items-center justify-content-between">
			<div class="custom-control custom-radio">
				<input type="radio" id="darkmode" name="customRadio" class="custom-control-input">
				<label class="custom-control-label" for="darkmode">Dark Mode</label>
			</div>
			<div class="custom-control custom-radio">
				<input type="radio" id="lightmode" name="customRadio" checked class="custom-control-input">
				<label class="custom-control-label" for="lightmode">Light Mode</label>
			</div>
		</div>
		<hr/>
		<div class="custom-control custom-switch">
			<input type="checkbox" class="custom-control-input" id="DarkSidebar">
			<label class="custom-control-label" for="DarkSidebar">Dark Sidebar</label>
		</div>
		<hr/>
		<div class="custom-control custom-switch">
			<input type="checkbox" class="custom-control-input" id="ColorLessIcons">
			<label class="custom-control-label" for="ColorLessIcons">Color Less Icons</label>
		</div>
	</div>
</div>
<!--end switcher-->
